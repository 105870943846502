import { Card as BootstrapCard } from 'react-bootstrap';
import styled from 'styled-components';
import { colorBackground } from '@/utils/palette';
import { borderRadius, shadow } from '@/utils/styles';
import { left } from '@/utils/directions';

export const Card = styled(BootstrapCard)`
  width: 100%;
  padding-bottom: 30px;
  height: ${props => (props.height || '100%')};
  border: none;
  background-color: transparent;
`;

export const CardPage = styled(BootstrapCard)`
  width: 100%;
  padding-bottom: 30px;
  height: ${props => (props.height || '100%')};
  border: none;
  background-color: transparent;
`;

export const CardPageBody = styled(Card.Body)`
  height: 100%;
  background-color: ${colorBackground};
  border-radius: ${borderRadius};
  box-shadow: ${shadow};
  padding: 20px;
`;

export const CardPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const CardPageTitleWrap = styled.div`  
  position: relative;
  text-align: ${left};

  &:not(:first-child) {
    margin-top: 40px;
  }

  * {
    margin-bottom: 0;
  }
`;

export const CardPageButtonWrap = styled.div`
  padding-bottom: 10px;
  padding-right: 16px;
`;

export const CardPageGridWrap = styled.div`
  padding-bottom: 20;
  width: '100%'
`;

export const CardPageTitle = styled.h5`
  font-size: 24px;
  text-align: ${left};
  font-weight: 500;
  ${props => props.centered && `
    text-align: center;
  `}
`;

export const CardPageSubhead = styled.p`
  text-transform: none;
  font-size: 13px;
  line-height: 18px;
  opacity: 0.7;
  margin-top: 11px;
`;

export const CardPageChartBoxTitleWrap = styled.h6`
  font-size: 16px;
  text-align: ${left};
  font-weight: 500;
  text-transform: uppercase;
  ${props => props.centered && `
    text-align: center;
  `}
`;
export const CardPageChartBoxWrap = styled.div`
  padding-top: 10px;
`;

export const CardPageFilterWrapper = styled.div`
  margin-bottom: 10px;
`;

