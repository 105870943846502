import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {Col, Container, Row} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormTextField } from "../../shared/components/form/material-controls/TextField";
import { searchService } from "../../reducers/cleverChain/cleverChainAction";
import { enqueueSnackbar } from "notistack";
import SearchIcon from "mdi-react/SearchIcon";
import CleverChainInfoModal from "./components/CleverChainInfoModal";
import {Button} from "@/shared/components/Button";
import {
    CardPage,
    CardPageBody, CardPageGridWrap,
    CardPageSubhead,
    CardPageTitle,
    CardPageTitleWrap
} from "../../shared/components/CardPage";

const CleverChain = () => {
    const dispatch = useDispatch();
    const { cleverChainsData, isSearchingService, searchServiceError, searchedService } = useSelector(({ cleverChain }) => cleverChain);
    const [ selectedRow, setSelectedRow ] = useState({});
    const [ showCleverChainInfoModal, setShowCleverChainInfoModal ] = useState(false);
    const { handleSubmit, reset, control } = useForm();
    const [ companies, setCompanies ] = useState([]);

    const columns = [
        {
            field: 'country',
            headerName: 'Country',
            flex: 1,
        },
        {
            field: 'companyName',
            headerName: 'Company Name',
            flex: 1,
        },
        {
            field: 'companyCode',
            headerName: 'Company Code',
            flex: 1,
        },
        {
            headerName: 'Address',
            field: 'address',
            width: 600,
            valueGetter: (data) => {
                if (data?.row?.addresses && data?.row?.addresses?.length > 0) {
                    return data.row.addresses[0].addressInOneLine;
                }
                return '';
            },
        },
        {
            headerName: 'Address Type',
            field: 'addressType',
            valueGetter: (data) => {
                if (data?.row?.location && data?.row?.location?.length > 0) {
                    return data.row.location[0].description;
                }
                return '';
            },
            minWidth: 120,
        },
        {
            field: 'registrationNumber',
            headerName: 'Registration Number',
            flex: 1,
        },
        {
            field: 'companyStatus',
            headerName: 'Company Status',
            minWidth: 120,
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => {
                return [
                    <GridActionsCellItem
                        key={'action-'+params.registrationNumber+'-'+params.companyCode}
                        icon={<SearchIcon />}
                        label="Detail"
                        onClick={() => handleClickViewCompanyDetail(params.row)}
                    />,
                ];
            },
        },
    ];

    useEffect(() => {
        if (searchedService) {
            enqueueSnackbar(searchedService, {variant: "success"});
        }
    }, [searchedService]);

    useEffect(() => {
        if (searchServiceError) {
            enqueueSnackbar(searchServiceError, {variant: "error"});
        }
    }, [searchServiceError]);

    useEffect(() => {
        setCompanies(cleverChainsData?.companies ?? []);
    }, [cleverChainsData]);

    const handleClickViewCompanyDetail = (row) => {
        setSelectedRow(row);
        setShowCleverChainInfoModal(true);
    }

    const onHideCleverChainInfoModal = () => {
        setShowCleverChainInfoModal(false);
        setSelectedRow({});
    };

    const checkFilters = () => {
        return control.getFieldState('countryISO').invalid || (!control._formValues.companyName && !control._formValues.companyCode);
    }

    const checkNameOrCompany = (field, controls) => {
        return (controls.companyName || controls.companyCode)? true : "Company Name or Company Code must be specified";
    }

    const handleClickApplyFilters = (data) => {
        if (checkFilters()) {
            return;
        }

        var keyValues = {};

        keyValues["countryISO"] = data.countryISO;
        if(data.companyName){
            keyValues["companyName"] = data.companyName;
        }
        if(data.companyCode){
            keyValues["companyCode"] = data.companyCode;
        }

        dispatch(searchService(keyValues));
    }

    const setRowId = (param) => {
        return param.registrationNumber + '-' + param.companyCode;
    }

    return(
        <Container>
            <CardPage>
                <CardPageBody>
                    <CardPageTitleWrap>
                        <CardPageTitle variant="h5">Clever Chain</CardPageTitle>
                        <CardPageSubhead variant="subtitle2" gutterBottom>
                            Here you can view your Clever Chain.
                        </CardPageSubhead>
                    </CardPageTitleWrap>

                    <CardPageGridWrap>

                        <div>
                            <Row>
                                <Col>
                                    <FormTextField
                                        name="countryISO"
                                        label="Country Code"
                                        control={control}
                                        rules={{ required: 'This field is required' }}
                                    />
                                </Col>
                                <Col>
                                    <FormTextField
                                        name="companyName"
                                        label="Company Name"
                                        control={control}
                                        rules={{
                                            validate: (companyName, controls) => checkNameOrCompany(companyName, controls)
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <FormTextField
                                        name="companyCode"
                                        label="Company Code"
                                        control={control}
                                        rules={{
                                            validate: (companyCode, controls) => checkNameOrCompany(companyCode, controls)
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '1%' }}>
                                <Col style={{ display: 'flex', justifyContent: 'end'}}>
                                    <Button
                                        size='large'
                                        variant="outline-danger"
                                        onClick={reset}>
                                        Reset
                                    </Button>
                                    <Button
                                        size='large'
                                        variant="outline-success"
                                        onClick={handleSubmit(handleClickApplyFilters)}>
                                        Search
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <div style={{ paddingBottom: 20, width: '100%' }}>
                                <DataGrid
                                    autoHeight
                                    density="compact"
                                    disableRowSelectionOnClick
                                    rowSelection={false}
                                    checkboxSelection={false}
                                    getRowId={(param) => setRowId(param)}
                                    loading={isSearchingService}
                                    rows={companies}
                                    columns={columns}
                                    slots={{ toolbar: GridToolbar }}
                                    slotProps={{
                                        toolbar: {
                                            showQuickFilter: false
                                        },
                                    }}
                                    sx={{
                                        [`& .${gridClasses.cell}`]: {
                                            py: 1,
                                        },
                                    }}
                                    initialState={{
                                        pagination: { paginationModel: { pageSize: 15 } },
                                    }}
                                    pageSizeOptions={[15, 30, 50, 100]}
                                />
                            </div>
                        </div>


                        <CleverChainInfoModal
                            onHideModal={onHideCleverChainInfoModal}
                            open={showCleverChainInfoModal}
                            companyNumber={selectedRow.companyCode}
                            companyISO={selectedRow.country}
                        />
                    </CardPageGridWrap>
                </CardPageBody>
            </CardPage>
        </Container>
    )
}

export default CleverChain;
